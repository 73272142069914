import clsx from "clsx"
import React, { FC } from "react"

export interface HelperTextProps {
  color?: string
  className?: string
}

const HelperText: FC<HelperTextProps> = ({
  children,
  color = "gray-500",
  className,
}) => {
  return (
    <div
      className={clsx(
        `text-sm text-left text-${color} text-semibold mt-1 mb-1`,
        className
      )}
      children={children}
    />
  )
}

export default HelperText
