import clsx from "clsx"
import React from "react"

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string
  label: string
  id: string
  placeholder?: string
  type?: string
  color?: string
  error?: boolean
  required?: boolean
}

const Input = React.forwardRef((props: InputProps, ref) => {
  const {
    id,
    label,
    name,
    placeholder,
    color = "orange",
    type = "text",
    error,
    required,
    ...restProps
  } = props
  return (
    <div>
      <label
        className={clsx(`block text-sm text-left font-medium text-gray-700`, {
          "text-red-700": error,
        })}
        htmlFor={id}
      >
        {label}
        {required ? (
          <span className="text-semibold text-red-700">*</span>
        ) : null}
      </label>
      <div className="mt-1">
        <input
          ref={ref as React.LegacyRef<HTMLInputElement>}
          className={clsx(
            `appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-${color}-500 focus:border-${color}-500 sm:text-sm`,
            { "border-red-700": error, "border-2": error }
          )}
          id={id}
          name={name}
          type={type}
          required={required}
          placeholder={placeholder}
          {...restProps}
        />
      </div>
    </div>
  )
})

export default Input
